@use 'responsive';

html {
  scroll-behavior: smooth;
}

body {
  letter-spacing: 0;
  overflow-x: hidden;

  @include responsive.media-lt(xs) {
    h1 {
      font-size: 24px;
    }

    h2 {
      font-size: 18px
    }

    h3 {
      font-size: 16px;
    }

    p {
      font-size: 14px;
    }
  }

  .tab-container {
    h3 {
      margin-bottom: 16px;
      color: var(--primary-color);
    }

    h4 {
      font-weight: 500;
      color: #5f5f5f;
    }
  }

  .mat-divider {
    margin-top: var(--theme-default-margin);
    margin-bottom: var(--theme-wide-margin);
  }

  form {
    .form-row {
      margin-bottom: var(--theme-default-margin);
    }
  }
}

.typography-tooltip {
  font-family: 'Source Sans Pro', serif;
  font-size: 13px;
}
