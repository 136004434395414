/* You can add global styles to this file, and also import other style files */

// Project styles
@use 'app';

// ----------------------------------------------------------------------------
// All scss files below are added in angular.json, they live in libs/styles
// {projectName}.architect.build.options.stylePreprocessorOptions.includePaths

// Accredible styles
@use 'colors';
@use 'variables';
@use 'accessibility';
@use 'framework';
@use 'ghosts';
@use 'mixins';
@use 'reset';
@use 'responsive';
@use 'libs/new-styles/new-theme';

// Material styles overrides
@use 'material/mat-button-overrides';
@use 'material/mat-dialog-overrides';
@use 'material/mat-elements-overrides';
@use 'material/mat-form-overrides';
@use 'material/mat-menu-overrides';
@use 'material/mat-select-overrides';
@use 'material/mat-tabs-overrides';
@use 'material/mat-typography-overrides';

// Accredible Font
@use 'acc-icon-font/style';

// Accredible New Icon Font
@use 'acc-new-icon-font/style' as style-new-icon-font;
